
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";
    import PreLoad from "@/helpers/PreLoad";

    export default defineComponent({
        components: {
            Language,
        },
        setup: () => {
            PreLoad.hide();

            return {};
        }
    });
