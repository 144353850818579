<template>
    <div class="view home">
        <div class="internal nav-with-text">
            <div class="homepage-body" role="main" aria-label="homepage-main">
                <nav class="top">
                    <Language/>
                </nav>

                <h1 v-html="$t('homepage.header')"></h1>

                <p class="desc" v-html="$t('homepage.description')"></p>

                <div class="qr-code">
                    <div>
                        <img src="/assets/qr.svg" :alt="$t('homepage.example')"/>
                    </div>
                    <p>{{ $t('homepage.example') }}</p>
                </div>

                <div class="powered-by">
                    <span>{{ $t('general.powered_by')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/views/_homepage";
</style>

<script lang="ts">
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";
    import PreLoad from "@/helpers/PreLoad";

    export default defineComponent({
        components: {
            Language,
        },
        setup: () => {
            PreLoad.hide();

            return {};
        }
    });
</script>
